import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import "./Blogs.scss"
import Comingsoon from "../Comingsoon";


function Blogs() {
    return (
        <div className="blog">
            <Particle />
            <Comingsoon />
        </div>

    );
}

export default Blogs;
