import React from "react";


function Comingsoon() {

    return (

        // Temporary Lines Start
        <div className="text-center text-white" style={{ marginTop: "400px", marginBottom: "400px" }}>
            <marquee behavior="" direction="">
                <h2 className="orange "> <b>Coming Soon :)</b></h2>
            </marquee>
        </div>
        // Temporary Lines End

    );
}

export default Comingsoon;
