import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";
import "./About.scss"

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <p style={{ textAlign: "justify", fontSize: "27px" }}>
          Techy<span className="orange">Pedia</span> LTD is a UK-based Software Development Agency with a global presence and a strong
          commitment to delivering innovative solutions. We are specialized in a wide range of services including <span className="orange">Web Development</span>,
          <span className="orange"> Digital Marketing</span>, <span className="orange">Graphic Designing</span>, <span className="orange">Content Writing</span>,
          <span className="orange"> Civil Engineering Solutions</span>, <span className="orange">Cloud Services</span>, <span className="orange">Secure Banking Solutions</span>,
          and more.
        </p>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
