import React from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";
import Particle from "../Particle";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import PhoneInput from 'react-phone-number-input'
import { useState } from "react";
import axios from "axios";
import "./Career.scss"



function Career() {
  // Form States
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('');
  const [skills, setSkills] = useState('');

  const [showMessage, setShowMessage] = useState(false)
  const [showForm, setShowForm] = useState(true)

  // Submit Event
  const handleSubmit = (e) => {
    e.preventDefault()
    setShowForm(false)
    setShowMessage(true)
    // console.log(name, email, phone, skills)

    const data = {
      Name: name,
      Email: email,
      Phone: phone,
      Country: country,
      Skills: skills
    }
    axios.post('https://sheet.best/api/sheets/6be723f2-ab63-48c2-8b31-05209c5974c0', data).then((response) => {
      // console.log(response)
      // Clear Form
      setName('');
      setEmail('');
      setPhone('');
      setCountry('');
      setSkills('');
    })
  }


  return (
    <Container fluid className="project-section">
      {/* <Particle /> */}
      <Container>
        <div className="career-content">
          <div className="form-head">
            <h1 className="orange">Career Form</h1>
            <p>Join Our Team: Where Opportunity Meets Innovation!</p>
          </div>
          <Row className="justify-content-md-center">
            <Col md={6}>
              {
                showForm &&
                <Form className="career-form" onSubmit={handleSubmit}>

                  <Form.Group className="mb-3">
                    <Form.Control className="form-field" required type="text" placeholder="Full Name" onChange={(e) => setName(e.target.value)} value={name} />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Control className="form-field" required type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} value={email} />
                  </Form.Group>

                  <Form.Group className="mb-3" >
                    <Form.Control className="form-field" required type="phone" placeholder="Phone" onChange={(e) => setPhone(e.target.value)} value={phone} />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Control className="form-field" required type="text" placeholder="Country" onChange={(e) => setCountry(e.target.value)} value={country} />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Control className="form-field" required placeholder="Enter Your Skills e.g, Software Development, Digital Marketing" as="textarea" rows={3} onChange={(e) => setSkills(e.target.value)} value={skills} />
                  </Form.Group>

                  <Button className="form-btn" variant="primary" type="submit" onClick={handleSubmit}>
                    Submit
                  </Button>
                </Form>
              }

              {
                showMessage &&
                <div className="submit-message">
                  <p>That's Great!</p>
                  <p>Your Application Has Been Submitted and One of our Team Member will be in Touch with You as soon as possible.</p>
                </div>
              }

            </Col>

          </Row>

        </div>
      </Container>
    </Container >
  );
}

export default Career;
