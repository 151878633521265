import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Github from "./Github";
import Techstack from "./Techstack";
import Aboutcard from "./AboutCard";
import laptopImg from "../../Assets/about.png";
import Toolstack from "./Toolstack";
import { CgCPlusPlus } from "react-icons/cg";
import {
    DiJavascript1,
    DiReact,
    DiNodejs,
    DiMongodb,
    DiPython,
    DiGit,
    DiJava,
} from "react-icons/di";
import {
    SiRedis,
    SiFirebase,
    SiNextdotjs,
    SiSolidity,
    SiPostgresql,
    SiWeb3Dotjs
} from "react-icons/si";
import { TbBrandGolang } from "react-icons/tb";
import "./About.scss"

function Services() {
    return (


        <div>
            {/* What Sets us apart */}
            <h1 className="project-heading p-5">
                What <strong className="orange"> Services We Provide? </strong>
            </h1>
            <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>


                <Col md={3} className="services">
                    <div className="services-card">
                        <SiRedis />
                        <h2 className="orange">Artificial Intelligence</h2>
                        <p>Let AI transform your business efficiency and drive growth.</p>
                    </div>
                </Col>

                <Col md={3} className="services">
                    <div className="services-card">
                        <SiRedis />
                        <h2 className="orange">Machine Learning</h2>
                        <p>Harness the potential of your data to drive insights, predictions, and optimizations.</p>
                    </div>
                </Col>

                <Col md={3} className="services">
                    <div className="services-card">
                        <SiRedis />
                        <h2 className="orange">Cyber Security</h2>
                        <p>Protect your digital assets with our comprehensive cybersecurity solutions.</p>
                    </div>
                </Col>

                <Col md={3} className="services">
                    <div className="services-card">
                        <SiRedis />
                        <h2 className="orange">Software Development</h2>
                        <p>Customized Software solutions tailored to your business needs.</p>
                    </div>
                </Col>

                <Col md={3} className="services">
                    <div className="services-card">
                        <SiRedis />
                        <h2 className="orange">Graphic Designing</h2>
                        <p>Unlock Your Vision: Transforming Ideas into Stunning Visuals.</p>
                    </div>
                </Col>

                <Col md={3} className="services">
                    <div className="services-card">
                        <SiRedis />
                        <h2 className="orange">3D Modeling</h2>
                        <p>Elevate your projects with our unparalleled 3D modeling expertise.</p>
                    </div>
                </Col>

                <Col md={3} className="services">
                    <div className="services-card">
                        <SiRedis />
                        <h2 className="orange">Digital Marketing</h2>
                        <p>Strategic digital campaigns to boost your online presence</p>
                    </div>
                </Col>

                <Col md={3} className="services">
                    <div className="services-card">
                        <SiRedis />
                        <h2 className="orange">Content Writing</h2>
                        <p>Engaging content creation for websites, blogs, and marketing materials.</p>
                    </div>
                </Col>

                <Col md={3} className="services">
                    <div className="services-card">
                        <SiRedis />
                        <h2 className="orange">Civil Engineering</h2>
                        <p>Software solutions for the civil engineering sector.</p>
                    </div>
                </Col>

                <Col md={3} className="services">
                    <div className="services-card">
                        <SiRedis />
                        <h2 className="orange">Cloud Services</h2>
                        <p>Cloud-based solutions for data storage and management.</p>
                    </div>
                </Col>

                <Col md={3} className="services">
                    <div className="services-card">
                        <SiRedis />
                        <h2 className="orange">Banking Solutions</h2>
                        <p>Robust and secure software solutions for the banking industry.</p>
                    </div>
                </Col>


            </Row>
        </div>
    )
}

export default Services;
