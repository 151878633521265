import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Img1 from "../../Assets/about-img1.webp";
import Img2 from "../../Assets/about-img6.webp";
import Img3 from "../../Assets/about-img4.webp";
import Tilt from "react-parallax-tilt";
import Services from "../About/Services";
import Motive from "../About/Motive";
import {
  AiFillFacebook,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import "./Home.scss";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>

          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              WELCOME TO <span className="orange"> TECHYPEDIA </span>
            </h1>
            <p className="home-about-body">
              Where innovation meets implementation!
              <br />
              <br />
              At <b className="orange">Techypedia</b>, we're not just about writing lines of code; we're architects of
              digital solutions, craftsmen of cutting-edge technology, and partners in your journey towards
              digital transformation. Our mission is simple: to empower
              businesses and individuals with bespoke software solutions that drive efficiency, productivity, and growth.
            </p>
          </Col>

          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={Img1} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>

          {/* Services */}
          <Services />

          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={Img2} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>

          <Col md={8} className="home-about-description">
            <p className="home-about-body">
              With a team of seasoned developers, designers, and project managers, we specialize in turning ideas into
              reality. Whether you're a startup looking to disrupt the market, an enterprise aiming to streamline operations,
              or an individual with a vision, we've got the expertise and passion to bring your project to life.
            </p>
          </Col>

          {/* Motive */}
          <Motive />

          <Col md={8} className="home-about-description">
            <p className="home-about-body">
              From web and mobile applications to enterprise software and beyond, we thrive on challenges and excel in
              delivering scalable, robust, and user-centric solutions tailored to your unique needs. At <b className="orange">Techypedia</b>,
              we don't just deliver software; we deliver results.
            </p>
          </Col>

          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={Img3} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>

        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FOLLOW US</h1>
            <p>
              Feel free to <span className="orange">connect </span>with us
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://www.facebook.com/techypedia.co.uk/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillFacebook />
                </a>
              </li>



              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/techypedia/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>


              <li className="social-icons">
                <a
                  href="https://www.instagram.com/techypedia.co.uk/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
