import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Github from "./Github";
import Techstack from "./Techstack";
import Aboutcard from "./AboutCard";
import laptopImg from "../../Assets/about.png";
import Toolstack from "./Toolstack";
import { CgCPlusPlus } from "react-icons/cg";
import {
    DiJavascript1,
    DiReact,
    DiNodejs,
    DiMongodb,
    DiPython,
    DiGit,
    DiJava,
} from "react-icons/di";
import {
    SiRedis,
    SiFirebase,
    SiNextdotjs,
    SiSolidity,
    SiPostgresql,
} from "react-icons/si";
import { TbBrandGolang } from "react-icons/tb";
import "./About.scss"

function Motive() {
    return (


        <div>
            {/* What Sets us apart */}
            <h1 className="project-heading p-5">
                What <strong className="orange"> Sets Us Apart </strong>
            </h1>
            <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>

                <Col md={3} className="motive">
                    <div className="motive-card">
                        <SiRedis />
                        <h2 className="orange">Diverse Expertise</h2>
                        <p>Our team consists of skilled professionals from around the world, bringing diverse expertise in
                            software development and related services.</p>
                    </div>
                </Col>

                <Col md={3} className="motive">
                    <div className="motive-card">
                        <SiRedis />
                        <h2 className="orange">Innovation</h2>
                        <p>We embrace innovation and leverage the latest technologies to provide scalable and efficient solutions for
                            our clients.</p>
                    </div>
                </Col>

                <Col md={3} className="motive">
                    <div className="motive-card">
                        <SiRedis />
                        <h2 className="orange">Global Reach</h2>
                        <p>With a distributed team, we can offer around-the-clock support and collaborate seamlessly across
                            borders.</p>
                    </div>
                </Col>

            </Row>
        </div>
    )
}

export default Motive;
