import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Software Developers",
          "Freelancers",
          "Full Stack Developers",
          "Graphic Designers",
          "Open Source Contributors",
          "Digital Marketers",
          "Business Consultants",

        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 99,
      }}
    />
  );
}

export default Type;
