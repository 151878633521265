import React from "react";
import Comingsoon from "../Comingsoon";
import Particle from "../Particle";


function Team() {

    return (

        <div>
            <Particle />
            <Comingsoon />
        </div>



    );
}

export default Team;
