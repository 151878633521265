import React, { useRef, useState } from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";
import Particle from "../Particle";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import emailjs from '@emailjs/browser';
import "./Contact.scss"


function Contact() {
    const form = useRef();
    const [showForm, setShowForm] = useState(true)
    const [showMessage, setShowMessage] = useState(false)

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_ew2sn7b', 'template_itsuz3g', form.current, {
                publicKey: 'LJHqnK7G44lepG8Oz',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                },
                (error) => {
                    console.log('FAILED...');
                },
            );
    };

    const handleClick = () => {
        setShowForm(false)
        setShowMessage(true)
    }

    return (
        <Container fluid className="project-section">
            {/* <Particle /> */}
            <Container>
                <div className="career-content">
                    <div className="form-head">
                        <h1 className="orange">Contact Us</h1>
                        <p>Send us your message and a member of our team will be in touch with you as soon as possible.</p>
                    </div>
                    <Row className="justify-content-md-center">
                        <Col md={6}>
                            {
                                showForm &&

                                <Form className="contact-form" ref={form} onSubmit={sendEmail}>

                                    <Form.Group className="mb-3">
                                        <Form.Control className="form-field" required type="text" placeholder="Full Name" name="name" />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Control className="form-field" required type="email" placeholder="Email" name="email" />
                                    </Form.Group>

                                    <Form.Group className="mb-3" >
                                        <Form.Control className="form-field" required type="phone" placeholder="Phone" name="phone" />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Control className="form-field" required type="text" placeholder="Country" name="country" />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Control className="form-field" required placeholder="Enter Your Message Here" as="textarea" rows={4} name="message" />
                                    </Form.Group>


                                    <Button className="form-btn" variant="primary" type="submit" value="Send" onClick={handleClick}>
                                        Submit
                                    </Button>
                                </Form>
                            }

                            {
                                showMessage &&
                                <div className="submit-message">
                                    <p>That's Brilliant!</p>
                                    <p>Your Details have Been Submitted and One of our Team member will be in Touch with you as soon as Possible.</p>
                                </div>
                            }

                        </Col>

                    </Row>
                </div>
            </Container>
        </Container >
    );
}

export default Contact;
