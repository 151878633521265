import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import img1 from "../../Assets/about-img3.webp";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";
import "./Home.scss";
import { Link } from "react-router-dom";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                Hi There!{" "}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>
              </h1>

              <h1 className="heading-name">
                WELCOME TO
                <strong className="main-name"> TECHYPEDIA Ltd</strong>
              </h1>

              <div style={{ padding: 50, textAlign: "left" }}>
                <h2>We are a team of</h2>
                <Type />
              </div>

              <Row>
                <Col md={6}>
                  <div>
                    <Button href="/contact" className="home-contact-btn">Contact Us</Button>
                  </div>
                </Col>
                <Col md={6}>
                  <div>
                    <Button href="/quote" className="home-quote-btn"><span>Get a Quote</span></Button>
                  </div>
                </Col>
              </Row>

            </Col>

            <Col md={5} style={{ paddingBottom: 20 }}>
              <img
                src={img1}
                alt="home pic"
                className="img-fluid"
                style={{ maxHeight: "450px" }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;
