import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Github from "./Github";
import Motive from "./Motive";
import Services from "./Services";
import Techstack from "./Techstack";
import Aboutcard from "./AboutCard";
import laptopImg from "../../Assets/about.png";
import img1 from "../../Assets/about-img5.webp";
import Toolstack from "./Toolstack";
import { CgCPlusPlus } from "react-icons/cg";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiPython,
  DiGit,
  DiJava,
} from "react-icons/di";
import {
  SiRedis,
  SiFirebase,
  SiNextdotjs,
  SiSolidity,
  SiPostgresql,
} from "react-icons/si";
import { TbBrandGolang } from "react-icons/tb";
import "./About.scss"


function About() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>

        {/* About Card */}
        <Row style={{ justifyContent: "center", padding: "10px" }}>

          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              Who <strong className="orange">We are?</strong>
            </h1>
            <Aboutcard />
          </Col>

          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "50px" }}
            className="about-img"
          >
            <img src={img1} alt="about" className="img-fluid" />
          </Col>
        </Row>

        {/* Our Mission */}
        <Row style={{ justifyContent: "center", padding: "10px" }}>

          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "50px" }}
            className="about-img"
          >
            <img src={laptopImg} alt="about" className="img-fluid" />
          </Col>

          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              What is <strong className="orange">Our Mission</strong>
            </h1>

            <p style={{ textAlign: "justify", fontSize: "27px" }}>
              At Techy<span className="orange">Pedia</span> LTD, our mission is to empower businesses with Cutting-Edge Technology Solutions that
              Drive <span className="orange">Growth</span> and
              <span className="orange"> Efficiency</span>. We strive to exceed client expectations by delivering high-quality, tailor-made solutions.
            </p>
          </Col>
        </Row>


        {/* Motive */}
        <Motive />

        {/* Services */}
        <Services />

        {/* Professional Skillset */}
        <h1 className="project-heading">
          Professional <strong className="orange">Skillset </strong>
        </h1>
        <Techstack />


        {/* Tools */}
        <h1 className="project-heading">
          <strong className="orange">Tools</strong> We have experts of
        </h1>
        <Toolstack />


        {/* Github */}
        {/* <Github /> */}

      </Container>
    </Container>
  );
}

export default About;
