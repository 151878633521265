import React from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";
import Particle from "../Particle";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Comingsoon from "../Comingsoon"
import PhoneInput from 'react-phone-number-input'
import { useState } from "react";
import { useRef } from "react";
import emailjs from '@emailjs/browser';
import axios from "axios";
import "./Quote.scss"



function Quote() {

    const form = useRef();


    // Form States
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [companyWebsite, setCompanyWebsite] = useState('');
    const [projectDecs, setProjectDesc] = useState('');
    const [projectFeatures, setProjectFeatures] = useState('');
    const [targetField, setTargetField] = useState('');
    const [targetAudience, setTargetAudience] = useState('');
    const [specificTechnologies, setSpecificTechnologies] = useState('');
    const [startDate, setStartDate] = useState('');
    const [deadline, setDeadline] = useState('');
    const [budget, setBudget] = useState('');
    const [preferredComMethod, setPreferredComMethod] = useState('');
    const [termsnCons, setTermsnCons] = useState('');

    const [showMessage, setShowMessage] = useState(false)
    const [showForm, setShowForm] = useState(true)


    // Get today's date in DD-MM-YYYY format
    const disablePastDates = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const dd = String(today.getDate()).padStart(2, '0');
        return `${yyyy}-${mm}-${dd}`;
    };

    // Submit Event
    const handleSubmit = (e) => {
        e.preventDefault()
        setShowForm(false)
        setShowMessage(true)
        console.log(name, email, phone, termsnCons)

        //Emailjs Script
        emailjs
            .sendForm('service_ew2sn7b', 'template_kxb686h', form.current, {
                publicKey: 'LJHqnK7G44lepG8Oz',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                },
                (error) => {
                    console.log('FAILED...');
                },
            );

        // Spread Sheet script
        const data = {
            Name: name,
            Email: email,
            Phone: phone,
            CompanyName: companyName,
            CompanyWebsite: companyWebsite,
            ProjectDesc: projectDecs,
            ProjectFeatures: projectFeatures,
            TargetField: targetField,
            TargetAudience: targetAudience,
            SpecificTechnologies: specificTechnologies,
            StartDate: startDate,
            Deadline: deadline,
            Budget: budget,
            PreferredComMethod: preferredComMethod,
            TermsnCons: termsnCons,
        }
        axios.post('https://sheet.best/api/sheets/1dc2c03a-9f29-4b53-8e4f-16fcc9837bc5', data).then((response) => {
            console.log(termsnCons)
            // Clear Form
            setName('');
            setEmail('');
            setPhone('');
            setCompanyName('');
            setCompanyWebsite('');
            setProjectDesc('');
            setProjectFeatures('');
            setTargetField('');
            setTargetAudience('');
            setSpecificTechnologies('');
            setStartDate('');
            setDeadline('');
            setBudget('');
            setPreferredComMethod('');
            setTermsnCons('');
        })

    }


    return (

        //  <Comingsoon />

        < Container fluid className="project-section" >
            <Particle />
            <Container>
                <div className="career-content">

                    {
                        showForm &&
                        <Row className="justify-content-md-center">
                            <div className="form-head">
                                <h1 className="orange">Quote Details</h1>
                                <p>Fill out the form below and we’ll get back to you once we’ve processed your quote request.</p>
                            </div>
                            <Col md={6}>
                                <Form className="career-form" ref={form} >


                                    {/* Contact Information */}
                                    <h2 className="orange">Contact Information</h2>
                                    <Form.Group className="mb-3">
                                        <Form.Control className="form-field" required type="text" placeholder="Full Name" onChange={(e) => setName(e.target.value)} name="name" value={name} />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Control className="form-field" required type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} name="email" value={email} />
                                    </Form.Group>

                                    <Form.Group className="mb-3" >
                                        <Form.Control className="form-field" required type="phone" placeholder="Phone" onChange={(e) => setPhone(e.target.value)} name="phone" value={phone} />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Control className="form-field" required type="text" placeholder="Company Name" onChange={(e) => setCompanyName(e.target.value)} name="companyName" value={companyName} />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Control className="form-field" required type="text" placeholder="Company Website" onChange={(e) => setCompanyWebsite(e.target.value)} name="companyWebsite" value={companyWebsite} />
                                    </Form.Group>

                                    {/* Project Details */}
                                    <h2 className="orange">Project Details</h2>

                                    <Form.Group className="mb-3">
                                        <Form.Control className="form-field" required placeholder="Brief Description of Project" as="textarea" rows={3} onChange={(e) => setProjectDesc(e.target.value)} name="projectDesc" value={projectDecs} />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Control className="form-field" required placeholder="Project Features and Functionalities" as="textarea" rows={3} onChange={(e) => setProjectFeatures(e.target.value)} name="projectFeatures" value={projectFeatures} />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Control className="form-field" required type="text" placeholder="Target Platforms(Web, Mobile, Desktop)" onChange={(e) => setTargetField(e.target.value)} name="targetField" value={targetField} />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Control className="form-field" required type="text" placeholder="Target Audiance" onChange={(e) => setTargetAudience(e.target.value)} name="targetAudience" value={targetAudience} />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Control className="form-field" required placeholder="Any Specific Technologies or Framework Requested" as="textarea" rows={3} onChange={(e) => setSpecificTechnologies(e.target.value)} name="specificTechnologies" value={specificTechnologies} />
                                    </Form.Group>

                                    {/* Timeline */}
                                    <h2 className="orange">Timeline</h2>

                                    <Form.Group className="mb-3">
                                        <Form.Control className="form-field" required type="date" placeholder="Desired Start Date" onChange={(e) => setStartDate(e.target.value)} name="startDate" value={startDate} min={disablePastDates()} />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Control className="form-field" required type="date" placeholder="Project Deadline" onChange={(e) => setDeadline(e.target.value)} name="deadline" value={deadline} min={disablePastDates()} />
                                    </Form.Group>

                                    {/* Budget */}
                                    <h2 className="orange">Budget</h2>
                                    <Form.Group className="mb-3">
                                        <Form.Control className="form-field" required type="text" placeholder="Budget($)" onChange={(e) => setBudget(e.target.value)} name="budget" value={budget} />
                                    </Form.Group>

                                    {/* Preferred Communication Method */}
                                    <h2 className="orange">Preferred Communication Method</h2>

                                    <Form.Group className="mb-3">
                                        <Form.Control className="form-field" required type="text" placeholder="Email, Phone, Video Meeting" onChange={(e) => setPreferredComMethod(e.target.value)} name="preferredComMethod" value={preferredComMethod} />
                                    </Form.Group>


                                    {/* Terms and Conditions Agreements */}
                                    <h2 className="orange">Terms And Conditions Agreement</h2>

                                    <Form.Check
                                        className="checkbox"
                                        inline
                                        label="I agree with the Terms and Conditions"
                                        name="termsnCons"
                                        onChange={(e) => setTermsnCons("Agreed")}
                                        value={termsnCons}
                                        type="checkbox"
                                    />


                                    <Button className="form-btn" variant="primary" type="submit" onClick={handleSubmit}>
                                        Submit
                                    </Button>

                                </Form>



                            </Col>

                        </Row>

                    }
                    {
                        showMessage &&
                        <div className="submit-message">
                            <p>That's Brilliant!</p>
                            <p>Your Quote Been Submitted and One of our Team member will be in Touch with you as soon as Possible.</p>
                        </div>
                    }
                </div>

            </Container>
        </Container >

    );
}

export default Quote;
